

.homepage {
    width: calc(100% - 40px) ;
    height: calc(100% - 40px) ;
    padding : 20px 20px ; 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-image: url('../assets/images/homepage-wallpaper.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    & .welcome {
        background-color: rgba(255, 255, 255, 0.596);
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        border-radius: 10px;
        padding: 0px 20px;
        -webkit-box-shadow: 9px 9px 10px -2px rgba(0,0,0,0.08); 
        box-shadow: 9px 9px 10px -2px rgba(0,0,0,0.08);
        border: 2px solid rgba(255, 255, 255, 0.448);

        & h1 {
            width: 100%;
            font-size: 25px;
        }

        & h2 {
            font-size: 20px;
            font-weight: 500;
        }
    }



    & .intro {
        height: 80px;
        display: flex;
        flex-direction: column;

        & h1, p {
            margin: 0px;
        }
        & h1 {
            margin: 10px 0px;
        }
    }

    & .container {
        height : calc(100% - 80px) ; 



        // * CONTAINER OF H1 + LINKS
        & .module-links {
            background-color: #FEFEFE;
            border-radius: 5px;
            //-webkit-box-shadow: 5px 5px 15px 0px #00000029; 
            //box-shadow: 5px 5px 15px 0px #00000029;
            display: flex;
            flex-direction: column;
            width: calc(400px - 40px);
            height : calc(100% - 20px) ; 
            padding: 10px 20px;
            border : 1px solid rgba(128, 128, 128, 0.162) ; 



            & > h1 {
                height: 30px;
                display: flex;
                align-items: center;
                color : darken($color_1, 10) ; 
                margin: 0;
            }

            // * CONTAINER OF LINKS
            & > div {
                width: 100%;
                height: calc(100% - 30px);
                & > div {
                    width : calc(100% - 20px) ; 
                    padding : 0px 10px ; 
                    margin: 10px 0px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 60px;
                    border-radius: 10px;
                    border: 1.5px solid rgba(128, 128, 128, 0.116) ; 
                    background : white ; 
                    cursor: pointer;

                    & > svg {
                        width: 20px;
                        padding-right : 10px ; 

                        & path {
                            fill : $color_3
                        }
                    }
        
                    & p {
                        flex: 1 1;
                        display: flex;
                        flex-direction: column;

                        & span {

                            &:first-child {
                                color: darken($color_3, 20);
                                font-weight: 600;
                            }

                            &:last-child {
                                color: darken($color_3, 40);
                                opacity : 0.5 ; 
                                font-style : italic ; 

                            }
                        }
        
                    }
        
                    & .open-in-tab {
                        width: 20px;
                        padding-left : 10px ; 
                        & svg {
                            width: 100%;
                            height: 100%;
                            opacity: 0.5; 

                            & path {
                                fill : darken($color_1, 20) ;

                            }
                        }
                    }

                    &:hover {
                        border: 1.5px solid $color_3 ; 
                        
                        & .open-in-tab svg {
                            opacity: 1;
                            & path {
                                fill : rgb(0, 123, 255) ; 
                            }
                        }
                            
                            
                        
                    }
                }
            }




        }

    }
}