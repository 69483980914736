
.merchandises-man {
    & label {
        @include labelStyle() ;
    }

    & input {
        @include inputStyle() ; 
    }

    & > .basepopup__container {
        width: 90%;
        height: 100%;
        max-height: calc(90% + 30px);

    }

    & .content {
        height: calc(100% - 20px - 30px);
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        position: relative;
    }

    & .button-return {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 10px;
        left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        & .tc-icons {
            @include buttonStyle("icons") ;
            width: 100% ;
            height:100%;

            & svg {
                width: 60%;
            }
        }
    }

    & .categorizer-manager {
        height: calc(100% - 50px);
        &__container {
            height: calc(100% - 50px);
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &--identification {
        height:90px;

        & input {
            padding: 0!important;
            text-align: center;
        }
    }

    &--container {
        width: 100%;
        height: calc(100% - 90px);
        display: flex;

        &__pictures {
            width: calc(50% - 20px);
            height: 100%;
            padding-right: 20px;
            border-right: 1px solid rgb(209, 208, 208);

            & .pictures-merch {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                &--nodata {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    & svg {
                       height: 300px;
                    }
                }

                &--preview {
                    width: calc(100% - 20px);
                    height: calc(100% - 80px - 20px);
                    border: 1px solid rgba(128, 128, 128, 0.258);
                    border-radius: 10px;
                    margin: 10px 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & img {
                        max-width: 80%;
                        max-height: 300px;
                    }
                }

                &--controller {
                    width: 100%;
                    height: 80px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    overflow-x: scroll;
                    overflow-y: visible;

                    & .selected .pictures-merch--controller--item--preview {
                        border: 1px solid $color_3;
                    }


                    &--item {
                        width: 60px;
                        height: 60px;
                        margin: 0px 5px;
                        position: relative;


                        &--preview {
                            height: calc(100% - 10px);
                            border: 1px solid rgba(128, 128, 128, 0.258);
                            border-radius: 10px;
                            overflow: hidden;
                            width: calc(60px - 10px);
                            padding: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;

                            & img {
                                height: 100%;
                                width: auto;
                            }

                            &:hover {
                                -webkit-box-shadow: 5px 6px 15px -1px rgba(0,0,0,0.214); 
                                box-shadow: 5px 6px 15px -1px rgba(0, 0, 0, 0.214);
                            }
                        }

                        &--delete {
                            position : absolute ; 
                            height: 20px;
                            width: 20px;
                            background-color: rgb(191, 2, 2);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            top: -10px;
                            right: -10px;
                            cursor: pointer;
                            

                            &:hover {
                                background-color: rgb(172, 1, 1);
                            }

                            & .tc-icons, svg {
                                width: 80%;
                                height: 80%;
                            }
                        }



                    }

                    & .add-pictures-merch {
                        width: 70px;
                        height: 70px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        & .tc-icons {
                            width: 40px;
                            height: 40px ; 
                            background : $color_gradient ;
                            border-radius: 50%;
                            margin: 0px 10px;
                            cursor: pointer; 

                            & svg {
                                width: 40%;
                                height: 40%;
                            }
                        }
                        

                    }
                }
            }


        }

        &__categorizer {
            width: calc(50%);
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

    }


    .nature-merch {
        position: absolute;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        background: white;
        z-index : 1500 ; 
        align-items: center;
        justify-content: center;
        display: flex ; 
        flex-direction: column;

        &--title {
            height: 60px;
            display: flex;
            justify-content: center;
            text-align: center;
            font-weight: bold;
            font-size: 18px;
            letter-spacing: 0.5px;
            color : #393939 ; 
        }

        &--container {
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;

            &--item {
                width: 300px;
                border-radius: 10px;
                border: 1px solid #DDDEE3;
                -webkit-box-shadow: 5px 6px 15px -1px rgba(0,0,0,0.214); 
                box-shadow: 5px 6px 15px -1px rgba(0, 0, 0, 0.214);
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 0px 20px;
                cursor: pointer;

                &:hover {
                    border: 1px solid #F3A73B;
                    -webkit-box-shadow: 5px 6px 15px -1px #fce6c6; 
                    box-shadow: 5px 6px 15px -1px #fce6c6;
                }

                &--svg {
                    height: 150px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    & svg {
                        height: 90%;
                    }
                }

                &--title {
                    height: 30px;
                    color : $color_1; 
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &--description {
                    width: calc(100% - 40px);
                    height: calc(120px - 40px);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: justify;
                    font-weight: 300;
                    font-style: italic;
                    text-align: center;
                }
            }



        }
    }



    & .identification-merch {
        display: flex;
        flex-direction: column;
        align-items: center;

        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &--files {
            width: 400px;
            border: none!important;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow-y: scroll;
            min-height: 100px;
            max-height: 250px;
            padding-top: 10px;
            margin: 10px 0px;
            transition : all 110ms ; 
            position: relative;
    
            & .file {
                width: calc(300px - 60px);
                margin: 5px 0px;
                border: 1px solid #DDDEE3;
                padding: 7.5px 30px;
                background-color: white;
                border-radius: 10px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: flex-start ; 
                flex-direction: column;

    
                & p {
                    margin : 0px ; 
                    width: 100%;
                    overflow: hidden;
                }
    
                & .mimetype {
                    font-style: italic;
                    font-weight: 600;
                    font-size: 12px;
    
                    &--valid {
                        color : rgb(2, 129, 2)
                    }
    
                    &--invalid {
                        color : rgb(172, 3, 3)
                    }
                }
                
    
                & .delete, .preview-icon {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    @include buttonStyle("icons") ; 
    
                }
    
                & .preview-icon{
                    left: 5px;
                    background-color:green ; 
    
                    &:hover {
                        background-color: rgb(1, 92, 1);
                    }
                }
    
                & .delete {
                    right: 5px;
                    background-color: red;
    
                    &:hover {
                        background-color: rgb(172, 1, 1);
                    }
                }
            }
    
            &--empty {
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;

    
                & svg {
                    height: 100%;
                }
            }
        }
    }

    & .characteristics-merch {
        

        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        & label, input, .search-select {
            max-width: 200px;
        }

        & .size {
            &--container {
                display: flex;

                & label {
                    font-size: 15px;
                    font-style: italic;
                    font-weight: 400;
                    opacity: 0.8;
                }

                & > div {
                    margin: 0px 10px;
                }

                & input {
                    width: 100px;
                    text-align: center;
                }
            }
        }
    }

    & .monetary-merch {


        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin : 10px 0px; 

        }

        & input {
            width: 100px;
            text-align: center;
            padding-left: 0;
        }

        &--status-error {
            font-size: 12px;
            font-weight: 600;
            color: red;
            margin-top: 20px!important;
        }

        &--summary {
            & p {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;

                & span {
                    &:first-child {
                        font-weight: 300;
                        font-size: 13px;
                    }

                    &:last-child {
                        font-weight: 600;
                        font-size: 17px;
                    }
                }

            }
        }
    }

    & .billing-merch {
        width:95%;
        height: 100%;

        & #id_supplier {
            z-index: 1001;
        }

        & #search-select {
            max-width: 250px;
            margin: auto;
        }

        & .raw-materials {
            width: 100%;
            height: 100%;
            display: flex;

            & .draggable-items, .dropzone {
                width: calc(50% - 20px);
                height: 100%;
                margin: 0px 10px;
                border-radius: 10px;
                border: 1px solid #DDDEE3;
                -webkit-box-shadow: 5px 6px 15px -1px rgba(0,0,0,0.214); 
                box-shadow: 5px 6px 15px -1px rgba(0, 0, 0, 0.214);


                & > div {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }


                &--search {
                    height: 40px;
                    border-bottom: 1px solid #DDDEE3;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                    & > input {
                        width: calc(100% - 40px);
                        height: 100%;
                        padding: 0;
                        padding : 0px 10px 0px 30px ; 
                        border: none;
                        border-top-left-radius: 10px ;
                        border-top-right-radius: 10px;


                        &:hover {
                            background-color: #cb464616!important;
                        }

                        &:focus {
                            background-color: #cb46460f;
                        }
                    }

                    & .tc-icons {
                        width: 12px;
                        height: 12px;
                        position: absolute;
                        left: 10px;
                    }
                }



                & .mini-load {
                    & > div{
                        border: none!important;
                        width: 30px;
                    }

                }

                &__status {
                    width: 100%;
                    height: 100%;
                    border: none!important;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & svg {
                        max-width: 60%;
                    }

                    & p {
                        width: 70%;
                        text-align: center;
                        font-weight: 300;
                        font-size: 14px;
                    }
                }

            }
            
            & .draggable-items--list {
                width: 100%;
                height: calc(100% - 50px);

                & .item__buttons--quantity {
                    background: #909090!important;
                    cursor: not-allowed!important;
                }
            }

            & .draggable-items--list, .dropzone {
                overflow: auto;
                display: flex;
                flex-direction: column;
                align-items: center;

                & > div {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    & > div {
                        width: calc(100% - 20px - 10px);
                        height: 70px;
                        padding: 5px;
                        margin: 5px 0px;
                        border-radius: 10px;
                        border: 1px solid #DDDEE3;
                        background-color: white;
                        cursor: pointer;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        &:first-child {
                            margin-top: 10px;
                        }

                        & .item {          

                            &--identification {
                                display: flex;
                                flex-direction: column-reverse;
                                & p {
                                        margin: 0;

                                    &:first-child{
                                        font-size: 15px;
                                        text-transform: capitalize;
                                    }

                                    &:last-child {
                                        font-size: 12px;
                                        font-weight: 300;
                                    }
                                }
                            }

                            &--monetary {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;
                                bottom: 0;
                                & p {
                                    margin: 0;

                                    &:first-child{
                                        font-size: 15px;
                                        text-transform: capitalize;
                                    }

                                    &:last-child {
                                        font-size: 12px;
                                        font-weight: 300;
                                    }
                                }
                            }

                            &__buttons {
                                display: flex;
                                align-items: center;
                                position: absolute;
                                top: -10px;
                                right: -5px;

                                & > button {
                                    width: 25px;
                                    height: 25px;
                                    border-radius: 50%;
                                    border: none;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    cursor: pointer;

                                    & .tc-icons {
                                        width: 90% ; 
                                        height: 90%;

                                        & svg {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }

                                }
                                
                                &--quantity {
                                    background-color: darken($color_3, 10);
                                    color: white;
                                    margin-right: 3px;

                                    & span {


                                        &:first-child {
                                            font-size: 9px;
                                        }
                                    }

                                }

                                &--delete {
                                    background-color: $color_1;
                                }


                            }
                        }
                    }
                }

            }

            & .dropzone {
                & > div {
                    & > div {
                        margin: 7.5px 0px;
                        cursor: default;
                        &:first-child {
                            margin-top: 15px;
                        }

                        &:hover {
                            border: 1px solid $color_3;
                        }
                    }
                }
            }

            & .quantity-popup {
                height: calc(100% + 80px);
                position: absolute;
                background-color: #ffffff9c;


                &--container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    & .main-button {
                        margin : 20px 0px 0px 0px ; 
                        width: 100px;
                    }

                    & > p {
                        margin: 0px 0px 5px 0px;
                        font-weight: 600;
                        font-style: italic;
                        font-size: 13px;
                        color: rgb(0, 148, 0);
                    }

                    & input {
                        text-align: center;
                        padding: 0;
                        margin: 0px 10px;
                    }
                }
            }
        }
    }

    & .qrcode-merch {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: hidden;

        & > div {
            width: 100%;

            & > div {
                width: 100%;
                margin: 5px 0px;
                display: flex;
                flex-direction: column;
                align-items: center;

                &:first-child p {
                    border-top: none;
                }

                & p {
                    font-weight: 600;
                    color : $color_2 ; 
                    padding-top: 10px;
                    border-top: 1px solid rgba(128,128,128,.258);
                    margin: 10px 0px 5px 0px;
                }

                & img {
                    width : 300px; 
                }
            }
        }
    }

    & .main-button {
        margin: 20px 0px;
    }
}