html, body, #root {
    width: 100%;
    height: 100%;
    display: flex;
    font-family: $font!important;
    background-color: $background_app ;
    overflow: hidden;
    user-select: none;
    margin: 0;
}

.tc-datepicker__day--selected {
    background-color: $color_3;
}

.tc-datepicker__day--keyboard-selected {
    background-color: rgba(1, 180, 1, 0.235)!important;
}

.tc-icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

h1 {
    color : $color_1 ; 
    font-size: 18px;
}

p {
    color : rgb(21, 13, 13) ; 
    font-size: 14px;
}

.app {
    width : calc(100% - 60px) ; 
    height: calc(100% - 50px);
    margin-left: 60px;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1;
        height: 100%;
        background-color: #F4F5F6;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow: hidden;
    }

}


.underconstruction {
    width: calc(100% - 50px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 50px;
    & svg {
        height: 50%;
    }

    & p { 
        font-size: 22px;
        font-weight: bold;
    }
}


.checking-identity, .redirection-user {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    & svg {
        width: 300px;
    }

    & p {
        margin-top: 30px;
        font-size: 17px;
        font-weight: 600;
    }
}

.redirection-user p {
    color : rgb(181, 1, 1)
}

.tc-table {

    &__header {

        & .return svg path {
            fill : red!important
        }

        & .save svg path {
            fill : rgb(47, 148, 47)
        }

        & .editing-active svg path {
            fill : $color_3!important; 
            
        }
    }
    &__thead {
        background: #E79018!important;
    }

    &__tbody {

        & input,div, p {
            font-family: $font;
            font-size: 13px;
            color: #383838;
        }

        & .events {
            &-select {
                background-color: rgba(254, 232, 192, 0.657);
            }
        }
        
        &--row{
            transition : all 20ms ; 
            font-size: 13px;
            &:hover {
                background-color: rgba(246, 199, 142, 0.162);
            }

            &--column {
                transition : all 20ms ; 
            }
        }

        & .editing {

            & > input {
                font-family: $font;
                font-size: 13px;

                &:focus {
                    background-color: #48aa2a34;
                }
            }
        }


    }

    &__footer {
        background: #E79018!important;
    }
}

.totals-view {
    position: absolute;
    bottom: 0;
    left: 0px;
    height: 30px;
    display: flex;
    align-items: center;
    z-index: 2000;
    width: 180px;
    padding : 0px 10px ; 

    & p {
        width: 100%;
        height: 100%;
        //background-color: $color_2;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: default;

    }

    & > p {
        height: calc(100% - 2px);
        margin: 0;
        font-weight: bold !important;
        display: flex;
        justify-content: space-between;
        align-items: center; 
        z-index: 2000;
    }

    &--detail {
        width: calc(100%);
        position: absolute;
        background-color: $color_3;
        //bottom: calc(100% - 2px);
        left: 0px;
        z-index: 1000;

        & p {
            width: calc(100% - 20px);

            padding : 0px 10px ; 
            opacity: 0.9;
            height: 40px;
            margin: 0;


            &:hover {
                background-color : $color_4 ; 
            }

        }
        
    }
}

.dropdown {
    @include dropdownStyle() ; 
}

.preview-file-mod {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index : 2600 ; 
    background-color: #030905e3;
    display: flex;
    justify-content: center;
    align-items: center;

    &--close {
        width: 30px;
        height: 30px;
        position: fixed;
        top: 10px;
        right: 10px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        z-index: 2000;

        & .tc-icons, svg {
            width: 100%;
            height: 100%;
        }
    }

    &--background {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index  : 1200
    }

    &--container {
        width: 80%;
        height: 90%;
        display: flex;
        z-index: 1500;
        align-items: center;
        justify-content: center;

        & iframe {
            width: 100%;
            height: 100%;
            border: none;
        }

        & img {
            height: 100%;
        }
    }
}