// * ADD-ORGANIZATION
.add-organization {

    //POPUP CONTAINER
    & .content {
        min-width: 300px;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    //CATEGORIZER
    & .categorizer-manager {

        &__container {

            & > div {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                & > div {
                    margin-top: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }

            & label {
                @include labelStyle()
            }

            & input {
                margin-bottom: 5px;
                @include inputStyle()
            }
        }

        
    }

    //GENERALITIES
    & .generalities {

        & #logo {

            & .preview {
                max-width: 200px;
                max-height: 150px;
                margin: 5px 0px;
                position: relative;
                & img {
                    max-height: 150px;
                    border-radius: 5px;
                    max-width: 200px;
                }
        
                & .buttons {
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    display: flex;
        
                    & > div {
                        margin: 0px 2.5px ;
                        display: flex;
                        background-color: $color_4;
                        border-radius: 50%;
                        cursor: pointer;
        
                        & .tc-icons {
                            width: 25px;
                            height: 25px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
        
                            & svg {
                                width: 40%;
                                
                            }
                        }
                    }

                    & .delete {
                        background-color: rgb(203, 2, 2)!important
                    }
        

                }
            }
        }
        & .accountant {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;

            & label {
                display: flex;
                align-items: center;
            }
            & .switch {
                width: 40px;
                height: 20px;
                background-color: rgba(128, 128, 128, 0.494);
                border-radius: 10px;
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-right: 10px;

                & .handle {
                    width : 15px ; 
                    height: 15px;
                    border-radius: 50%;
                    background-color: $color_1;

                }

                &__active {
                    background-color: $color_4;

                    & .handle {
                        background-color: white;
                    }
                }
            }
        }
    }

    //MAIN BUTTON 
    & .main-button {
        margin-top: 10px;
    }


}