

.function-user-man {
    display: flex;
    flex-direction: column;


    & .basepopup__container {
        min-width: 300px;
        min-height: 300px;
    }

    & label {
        @include labelStyle() ; 
    }

    & input {
        @include inputStyle() ; 
        text-align: center;
        padding: 0px 10px;
    }

    & .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 100% ; 

        & > div {
            margin: 5px 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    & .dropdown {
        width: 200px;
        margin: 0;
    }

    & .selected_modules {
        width: 100%;
        display: flex;
        & > div {
            width: auto;
            height: 30px;
            margin : 10px 5px ; 
            background-color: $color_1;
            display: flex;
            align-items: center;
            border-radius: 50px;
            max-width: 150px;


            & .tc-icons {
                width: 20px;
                height: 20px;
                margin: 0px 5px;
                background-color: white;
                border-radius: 50px;
                cursor: pointer;


                & svg {
                    width: 60%;
                }
            }

            & > p {
                margin: 0;
                padding-right: 10px;
                color: white;
            }
        }
    }

    & .main-button {
        margin-top: 20px;
    }
}