// * LOADER
.loader {
    width : 100% ; 
    height : 100% ; 
    position : fixed ; 
    z-index: 5000;
    background: rgb(29,38,48);
    background: radial-gradient(circle, rgba(29,38,48,1) 0%, rgba(29, 38, 48, 0.839) 100%);
    display: flex;
    justify-content: center;
    align-items: center;

    &--container {
        position: relative;
        width: 100px;
        height: 130px;
        background: #fff;
        border-radius: 4px;


        &:before{
            content: '';
            position: absolute;
            width: 54px;
            height: 25px;
            left: 50%;
            top: 0;
            background-image:
            radial-gradient(ellipse at center, #0000 24%,#de3500 25%,#de3500 64%,#0000 65%),
            linear-gradient(to bottom, #0000 34%,#de3500 35%);
            background-size: 12px 12px , 100% auto;
            background-repeat: no-repeat;
            background-position: center top;
            transform: translate(-50% , -65%);
            box-shadow: 0 -3px rgba(0, 0, 0, 0.25) inset;
          }
        &:after{
            content: '';
            position: absolute;
            left: 50%;
            top: 20%;
            transform: translateX(-50%);
            width: 66%;
            height: 60%;
            background: linear-gradient(to bottom, #f79577 30%, #0000 31%);
            background-size: 100% 16px;
            animation: writeDown 2s ease-out infinite;
         }
    }

    
     @keyframes writeDown {
        0% { height: 0%; opacity: 0;}
        20%{ height: 0%; opacity: 1;}
        80% { height: 65%; opacity: 1;}
        100% { height: 65%; opacity: 0;}
     }
          


    &--message {
        position: relative;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        & p {
            position: absolute;
            width: 300px;
            display : flex ; 
            justify-content: center;
            align-items: center;
            text-align: center;
            top : calc(100%) ; 
            color : white ; 
            margin: 0;
            margin-top: 10px;
            font-size: 15px ; 
            font-weight: 500;

            transition: all 1s;
            animation: lighting 1s infinite;
        }
    
    }

    @keyframes lighting {
        0% {
            opacity: 0.5;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0.5;
        }
    }
    
}

// * MINI LOAD
.mini-load {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & .tc-icons {
        min-width: 20px;
        min-height: 20px;

        & svg {
            width: 100%;
            height: 100%;
            animation: spinner 0.6s linear infinite;
        }

    }

}

// * STATUS REQUEST
.statusRequest {
    position: fixed;
    z-index: 4900;
    border-radius: 5px;
    max-width: 300px;

    top: 20px;
    right: 20px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;
    //border : 1px solid rgba(128, 128, 128, 0.306) ; 
    -webkit-box-shadow: 0px 7px 9px -2px rgba(0,0,0,0.36); 
    box-shadow: 0px 7px 9px -2px rgba(0, 0, 0, 0.11);
    cursor: pointer;

    & > div {
        width: 100%;
        height: 10px;
    }

    & > p {
        width: calc(100% - 20px);
        padding: 10px 10px;
        flex : 1 1 ;
        font-size : 13px
    }
    &__error {
        &  > div {
            background-color: rgb(179, 2, 2);
            border: 1px solid rgb(179, 2, 2);
        }

        &  > p {
            color: rgb(145, 2, 2);
        }

    }
    


    &__success{
        &  > div {
            background-color: rgb(0, 141, 0);
            border: 1px solid rgb(0, 141, 0);
        }

        & p {
            color : rgb(1, 99, 1)
        }
    }


    &__info{
        &  > div {
            background-color: rgb(39, 39, 39);
            border: 1px solid rgb(39, 39, 39);
        }

        & p {
            color : rgb(39, 39, 39)
        }
    }
}

// * MAIN BUTTON
.main-button {
    background: $color_gradient;
    display: inline-flex;
    //width: auto;
    padding: 0px 10px!important;
    height: 30px;
    border-radius: 10px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    align-items: center;
}

// * CATEGORIZER-MANAGER 
.categorizer-manager {
    display: flex;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    & .resize {
        z-index: 1000;
        position: absolute;
        width: 30px;
        height: 30px;
        top: 35px;
        right: -15px;
        padding: 0!important;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none ; 
        @include buttonStyle("icons") ;

        
        

        & svg {
            width: 50%;
            height: 50%;
        }
    }

    &__header {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;


        & .active {
            opacity: 1;

            & p {
                font-weight: bold;
                color : $color_1; 
            }

            

            & svg path {
                fill : $color_1!important
            }
        }

        & > div {
            display: flex;
            align-items: center;
            margin: 0px 10px;
            opacity: 0.4;
            cursor: pointer;

            & .tc-icons {
                width: 20px;
                height: 20px;
                margin: 0px 5px;

                & svg {
                    width: 100%;
                    height: 100%;
                }
            }

            &:first-child .tc-icons {
                margin-left: 0;
            }

            & p {
                height: 100%;
                margin: 0;
                font-size: 15px;
                display: flex;
                align-items: center;
            }


        }
    }

    & .selected-modal {
        width: 100%;
        height: calc(100% - 50px);
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        border-radius: 5px;
        transition: width height 1s;


        &__container {
            position: relative;
            width: 100%;
            height: 100%;
            background-color: #ffffff;
            border: 1px solid rgba(128, 128, 128, 0.217);
            transition: all 0.4s;
            border-radius: 5px;
            overflow-x: hidden;

            & .resize {
                position: absolute;
                width: 30px;
                height: 30px;
                top: -10px;
                right: -10px;
                padding: 0!important;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none ; 
                @include buttonStyle("icons") ;
                z-index: 4000; 

                & svg {
                    width: 50%;
                    height: 50%;
                }
            }


            
        }

    }

    & .tableMagic {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }

    .tableMagicController {
        background-color: #fefefe;
    }

    & .fullscreen {
        margin: 0px;
        width: calc(100%)!important;
        height: 100%;
        z-index: 4000;
        position: fixed;


        & .selected-modal__background {
            width: 100%;
            height: 100%;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
            top: 0;
            left: 0;
            position: absolute;
            background-color: rgba(74, 74, 74, 0.328);

        }

        & .selected-modal__container {
            z-index: 2000;
            width: calc(100% - 40px);
            height: calc(100% - 40px) ;
        }
    }




}

// * BASE POPUP 
.basepopup {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0308049c ; 
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2500;


    &__background {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    &__container {
        display: flex;
        flex-direction: column;
        z-index: 2000;
        min-width: 100px;
        max-height : calc(80% + 30px) ; 
        border-radius: 3px;
        -webkit-box-shadow: 5px 5px 15px 0px #000000; 
        box-shadow: 5px 5px 15px 0px #0000004c;



        & .header {
            width: calc(100% - 10px);
            height: 30px;
            padding: 0px 5px;
            //background-color: $color_3;
            background: $color_gradient;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            & button {
                width: auto;
                height: 100%;
                background-color: transparent;
                border: none;


            }

            & .tc-icons {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                

                & svg {
                    height: 70%;
                    cursor: pointer;
                    opacity: 0.8;
                }
            }
        }

        & .content {
            width: calc(100% - 20px);
            padding: 10px 10px;
            background-color: #fefefe;
        }
    }
}

// * IMPORT 
.import {
    background-color: #fefefe;
    border: 1px solid rgb(222, 220, 220);
    border-radius: 10px;
    -webkit-box-shadow: 4px 4px 14px -4px rgba(26, 27, 26, 0.189);  
    box-shadow: -1px 7px 8px -4px rgba(26,27,26,.109) ; 
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    cursor: pointer;

    & > div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__intro {
        width: 90%;
        height: calc(90px);
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;

        
        & p {
            opacity: 0.6;
            margin : 10px 0px!important;
        }
        
    }

    &__illustration {
        height: calc(80% - 50px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;


        & svg {
            height: 300px;
            margin: 10px;
        }

        & p {
            width: 80%;
            margin: 10px 0px!important;
            font-size: 15px;
        }

    }

    &__dragMode {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.648);
        backdrop-filter: blur(10px);

        & svg {
            width: 30%;
        }
    }


    & h1, p {
        margin: 0;
        text-align: center;

    }

    &__uploadedFile {
        width: 90%;
        height: 40px;
        margin: 15px 0px;
        display: flex;
        align-items: center;

        & > div {
            display: flex;
            flex : 1 1 ; 
            padding: 5px;
            border-radius: 10px;
        }

        & .pending {
            //background-color: white;
            border: 1px solid #FF9800;
            background-image: linear-gradient(to right, #FF7D00, #FFA000);


            & .tc-icons {
                width: 50px;
                height: 30px;
            }
        }

        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }

        & .tc-icons {
            display: flex;
            justify-content: center;
            align-items: center;

            & svg {
                height: 90%;
            }

        }


        & p {
            flex : 1 1;
            padding-left: 3px;
            font-size: 15px; 
            text-align: justify;
            color: rgb(255, 255, 255);
        }

        & .load {
            //TOTAL 40*40
            width: 30px;
            height: 30px;
            margin-right: 5px;
            //opacity: 0.7;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            & .tc-icons {
                width: 90%;
            }

            & svg {
                animation: infinite 25s rotate;
            }


            &:hover {
                opacity: 1;
            }
        }

        & .error {
            border: 1px solid #e53935;
            background: linear-gradient(60deg, #e53935, #b71c1c);

            & .tc-icons {
                width: 40px;
                height: 30px;
            }
        }
    }
}

.import-reduced {
    width: 290px;
    padding : 10px 10px 10px 0px ;
    background-color: #FAFAFA;
    border : 1px solid rgba(128, 128, 128, 0.278) ; 
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .other {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    

    & .tc-icons{
        width : 80px; 
        justify-content: center;
        align-items: center;
        display: flex;
        & svg {
            width: 50%;
            height: auto;
        }
    }

    &--preview {
        max-width: 200px;
        max-height: 150px;
        margin: 5px 0px;
        position: relative;
        & img {
            max-height: 150px;
            border-radius: 5px;
            max-width: 200px;
        }

        & .buttons {
            position: absolute;
            top: -10px;
            right: -10px;
            display: flex;

            & > div {
                margin: 0px 2.5px ;
                display: flex;
                background-color: $color_4;
                border-radius: 50%;
                cursor: pointer;

                & .atomix-icons {
                    width: 25px;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & svg {
                        width: 50%;
                        
                    }
                }
            }

            &--delete {
                background-color: rgb(203, 2, 2)!important;
            }
        }
    }

    & .description {
        margin: 5px 0px; 
        & p { 
            margin: 0;
            &:first-child{
                font-size: 13px;
            }

            &:last-child{
                margin-top: 5px;
                font-size: 12px;
                font-style: italic;
            }
        }
    }

}

////////// ARE YOU SURE //////////`
/// 
.popup-warning {
    z-index: 3500;
}
.popup-warning .content {

    width: calc(100% - 60px);
    padding: 20px 30px;

    
    & p {
        text-align: center;
        max-width: 300px;
        margin-bottom: 20px;
    }


    & > div {
        display: flex;
        align-items: center;
        justify-content: center;

        & > div {
            border-radius: 10px;
            padding: 5px 10px;
            margin: 0px 10px;
            -webkit-box-shadow: 0px 7px 9px -2px rgba(0,0,0,0.36); 
            box-shadow: 0px 7px 9px -2px rgba(0, 0, 0, 0.026);
            cursor: pointer;

            &:first-child {
                background: $color_gradient;
                border: 1px solid $color_4;
                color: white;
            }

            &:last-child {
                background-color: whitesmoke;
                border: 1px solid rgba(1, 54, 1, 0.129);
            }
        }

    }
}

//SEARCH SELECT
.search-select, #search-select {
    z-index: 1000;
    margin-top: 10px;
    display: flex ; 
    justify-content: center ; 
    align-items: center ; 
    position: relative;



    & input {
        width: calc(100% - 40px);
        padding-left : 30px ; 
        padding-right: 10px ; 
        opacity: 1!important;
        z-index: 500; 
        @include inputStyle("search"); 
    }

    & .magnifying-glass {
        width: 15px;
        height: 15px;
        position: absolute;
        left: 10px;
        z-index: 2100; 

        & svg {
            width : 100%;
            height : 100%
        }
    }

    & .list {
        position: absolute;
        padding-top: 30px;
        top: 50%;
        width: calc(100% - 3px);
        background-color: white;
        border: 1.5px solid rgba(128, 128, 128, 0.79);
        border-radius: 10px;
        max-height: 250px;
        //padding: 10px 0px;
        padding-top: 15px;
        overflow-y: scroll;

        & > div {
            font-size: 13px;
        }

        & .add {
            width: calc(100% - 10px);
            height: 30px;
            padding: 0px 5px;
            //background-color: red;
            display: flex;
            align-items: center;
            cursor: pointer;

            & .tc-icons {
                width: 10px;
                padding: 0px 2.5px;

                & svg path {
                    fill : $color_2!important
                }
            }

            & p {
                font-style: italic;
                font-size: 14px;
                color : $color_2 ; 
            }

            &:hover p {
                font-weight: bold;
            }
        }

        &--element {
            width: calc(100% - 20px);
            padding: 0px 10px;
            height: 35px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:hover {
                background-color : rgba(249, 197, 56, 0.174)
            }
        }

        &--background {
            width: 100%;
            height: 100%;
            position: fixed;
            left: 0;
            top: 0;
        }

        & .mini-load .tc-icons {
                width: 30px;
                height: 30px;
                margin: 10px 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                //margin: 5px 0px;


        }

        & .list--nodata {
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;

        }
    }

    
    

    
    

}


///STEPS TEXT
.stepsText {
    height: 50px;
    display: flex;
    padding-left: 10px;
    align-items: center;
    //position : fixed ; 

    & h1 {
        font-weight: 500;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 700;
    }

    &__subCategories {
        height: 100%;
        display: flex;
        

        & div {
            height: 100%;
            display: flex;
            opacity: 0.7;
            & svg {
                margin: 0px 10px;
                width: 10px;


                & path {
                    fill : $color_1
                }
            }

            & p {
                font-weight: 600;
                font-size: 14px;
                display: flex;
                margin: 0;
                align-items: center;
            }


            &:last-child {
                opacity: 1;

                & svg {

    
                    & path {
                        fill : $color_3
                    }
                }

            }
            

        }

        




    }
}

//INFO ACCOUNT 
.info-account {
    top: 0;
    right: 10px;
    height: 50px;
    position: fixed;
    display : flex ; 
    align-items: center;

    & > div {
        padding: 0px 10px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .datetime {
        display: flex;
        padding-right: 10px;


        
        & p {
            padding : 0px 2.5px; 
            transition : all 110ms ; 

            &:hover {
                opacity: 1!important;
                font-weight: 500;
            }

            &:first-child::first-letter {
                text-transform: uppercase;
            }

            &:last-child {
                //background-color: red;
                text-align: end;
                margin-left: 10px;
                letter-spacing: 1px;

            }
            
        }
    }

    & .name {
        border-left: 1.5px solid rgba(149, 149, 149, 0.332);
    }

    & .name, .datetime p {
        font-size: 14px;
        color: #383838;
        cursor: normal;
        user-select: none;
    }

    & .buttons, .language {
        display: flex;
        border-left: 1.5px solid rgba(149, 149, 149, 0.332);

        & .tc-icons {
            margin: 0px 2.5px;
            padding: 5px 5px;
            cursor: pointer;
            & svg {
                width : 17.5px ; 
            }
        }

    }

    & .language {
        width: 55px;
        height: 20px;

        & > div {
            width: 90%;
            height: 100%;
            border-radius: 20px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition : all 110ms ; 

            & p {
                position: absolute;
                color: white;
                opacity: 0.7;
                margin: 0;
                font-size: 13px;
                transition : all 110ms ; 
            }

            & > div {
                width: 25px;
                height: 25px;
                position: absolute;
                transition : all 110ms ; 

                & svg {
                    width: 100%;
                    height: 100%;

                }
            }
        }
        

        & #en {
            background-color: #0D346B;
            

            & p {
                right: 7.5px;
            }

            & > div {
                left: -5px;
            }

        }

        & #fr {
            background-color: #3096D4 ; 

            & p {
                left: 7.5px;
            }

            & > div {
                left: calc(100% - 20px);
            }
        }
    }


}


// * BOOLEAN BUTTON 
.boolean-button {
    width: 40px;
    height: 20px;
    background-color: rgba(128, 128, 128, 0.494);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 10px;

    & .handle {
        width : 15px ; 
        height: 15px;
        border-radius: 50%;
        background-color: $color_1;

    }

    &__active {
        background-color: $color_4;

        & .handle {
            background-color: white;
        }
    }
}