

.transactions {
    display: flex;
    justify-content: space-between;
    padding: 60px 20px 20px 20px;
    width : calc(100% - 40px) ;
    height: calc(100% - 80px);
    position: relative;
    background-color: #fbfbfd!important;

    & #summary, #new-transaction, #chart, #history {
        background-color: white;
        border-radius: 10px;
        //-webkit-box-shadow: 5px 6px 15px -1px rgba(0,0,0,0.214); 
        //box-shadow: 5px 6px 15px -1px rgba(0, 0, 0, 0.214);
        border: 0.5px solid rgba(128, 128, 128, 0.258);
    }

    &__dateswap {
        position: absolute;
        top: 15px;
        right: 20px;
        display: flex;
        & .arrows {
            display: flex;
            align-items: center;

            & > p {
                max-width: 130px;
                text-align: center;
                color: rgb(130, 130, 130);
                text-transform: uppercase;
                margin: 0;
            }

            & > div {
                margin: 0px 10px;
            }
            & > div, .tc-icons {
                width: 25px;
                height: 25px;

                border-radius: 50%;
                cursor: pointer;

                &:hover .tc-icons {
                    background-color: $color_3;

                    & svg path {
                        fill : white!important
                    }
                }

                & .tc-icons {
                    background-color: #f3f4f6;
                }

                & svg {
                    width: 60%;
                    height: 60%;
                }
            }
        }



        & .swap_to {

            display: flex;
            height: 30px;
            margin-left: 10px;
            background-color: #f3f4f6;
            //-webkit-box-shadow: inset 0px 0px 9px 2px rgba(244,208,181,0.82); 
            //box-shadow: inset 0px 0px 9px 2px rgba(244,208,181,0.82);
            border-radius: 5px;
            padding: 2px 2px;



            & .active {
                background-color:white;
                cursor: initial!important;

                & p {
                    color: $color_3;
                    margin: 0;            
                    text-align: center;


                }
            }


            & > div {
                height: 100%;
                border-radius: 5px;
                padding: 0px 10px;
                cursor: pointer;
                transition: all 110ms;
                display: flex;
                align-items: center;
                justify-content: center;

                & p {
                    text-transform: capitalize;
                    color : #979aa6 ; 
                    font-weight: 600;
                }
            }
        }

    }

    & > div {
        &:nth-child(2) {
            width: 300px;
            height: 100%;
        }

        &:last-child {

        }



    }

    & h1 {
        color : $color_3 ; 
        font-size: 18px;
        margin: 0px 0px 5px 0px;

    }

    &__left-column {
        display: flex;
        flex-direction: column;
        align-items: center;

        & #new-transaction {
            width: 100%;
            height: calc(100% - 200px);
            display: flex;
            align-items: center;
            flex-direction: column;
            padding-bottom: 20px;

            & .swap_to {
                width: calc(100% - 20px);
                padding: 0px 10px;
                height: 50px;
                display: flex;
                align-items: center;
                border-bottom: 0.5px solid rgba(128, 128, 128, 0.258);
    
                & div, p {
                    display: flex;
                    align-items: center;
                }
    
                & p {
                    margin: 0;
                }
    
                &--container {
                    display: flex;
                    height: 30px;
                    margin-left: 10px;
                    background-color: #f3f4f6;
                    //-webkit-box-shadow: inset 0px 0px 9px 2px rgba(244,208,181,0.82); 
                    //box-shadow: inset 0px 0px 9px 2px rgba(244,208,181,0.82);
                    border-radius: 5px;
                    padding: 2px 2px;
    
                    & .active {
                        background-color:    white;
                        cursor: initial!important;
    
                        & p {
                            color: $color_3;
                            margin: 0;            
                            text-align: center;

                        }
                    }
    
                    & > div {
                        height: 100%;
                        border-radius: 5px;
                        padding: 0px 10px;
                        cursor: pointer;
                        transition: all 110ms;
    
                        & p {
                            text-transform: capitalize;
                            color : #979aa6 ; 
                            font-weight: 600;
                        }
                    }
                }
    
            }

            & #code {
                margin: 20px 0px;
                & p {
                    margin: 0;
                    text-align: center;

                    &:first-child {
                        font-weight: 300;
                        font-size: 10px;
                    }

                    &:last-child {
                        font-weight: 600;
                    }
                }
            }

            & label {
                margin: 0;
                text-align: center;
                font-weight: 300;
                font-size: 10px;
                margin-bottom: 4px;
            }


            & #date {
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;

                & input {
                    @include inputStyle(); 
                    text-align: center;
                }

                & .tc-datepicker-popper {
                    z-index: 1001;
                }

            }
    
            & .currencies_values {
                width: calc(100% - 80px);
                margin-bottom: 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
    
    
                & > div {
                    width: 100%;
                    position: relative;
    
                    & input {
                        width: calc(100% - 35px - 5px);
                        background-color: #f3f4f6;
                        border: 1px solid #f3f4f6; 
                        border-radius: 5px;
                        padding: 10px 35px 10px 5px  ; 
        
                        &:focus {
                            outline : none ; 
                            border: 1px solid $color_3;
                        }
                    }
    
                    &::after {
                        width: 25px;
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: white;
                        border: 2px solid rgba(128,128,128,.258);
                        border-radius: 5px;
                        right: 5px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 12px;
                        padding: 5px 10px;
    
                    }
    
                    &:nth-child(1) {
                        margin-bottom: 10px;
                        &::after {
                            content : "EUR"
                        }
                    }
    
                    &:nth-child(3)::after {
                        content : "IDR"
                    }
    
                }

                & > .tc-icons {
                    background-color: white;
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    z-index: 100;
                    border-radius: 50%;

                    
                }
    
    
                .exchangeRateForEuro {
                    position: absolute;
                    bottom: -30px;
                    color : $color_1 ; 
                    font-size: 12px;
                    left: 50%;
                    font-style: italic;
                    transform: translateX(-50%);
                    display: flex;
                    justify-content: center;
                }
    
    
    
    
    
                & .tc-minibox {
    
                }
            }
        }

        & #summary {
            width: calc(100% - 40px);
            height: calc(200px - 10px - 20px);
            margin-top: 10px;
            padding: 10px 20px;
            display: flex;
            flex-direction: column;

            & h1 {
                color : $color_3 ; 
                font-size: 18px;
                margin: 0px 0px 5px 0px;

            }

            & h2 {
                color : #323232; 
                font-size: 15px;
                margin: 0px 0px 10px 0px;
            }

            & .summary__progressBar {
                display: flex ; 
                align-items: center ; 
                flex : 1 1;
                position: relative;


                & .progress-bar-background {
                    flex: 1 ; 
                    margin: 0 10px ; 
                    background: #e0e0e0 ; 
                    border-radius: 8px ; 
                    overflow: hidden;
                }

                & .progress-bar-foreground {
                    height: 20px ; 
                    width: 0 ;  // La largeur initiale doit être 0 et contrôlée par JavaScript
                    background: $color_3 ;
                    transition: all 220ms ; 
                    border-radius: 8px ; 
                }

                & .amount {
                    position: absolute;
                    top : 5px ; 

                    &:first-child {
                        left: 0px;
                    }

                    &:last-child {
                        right: 0px;

                        & p:first-child {
                            text-align: end;
                        }

                    }

                    & p {
                        margin: 0;
                        &:first-child {
                            font-size: 10px;
                        }

                        &:last-child {
                            font-size: 20px;
                            position: relative;
                            padding-right: 8px;
                            &:after {
                                content : "€";
                                display: flex;
                                position: absolute;
                                top: 0px;
                                right: 0;
                                font-size: 12px;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }



    }

    &__container {
        width: calc(100% - 20px - 350px);
        height: 100%;
        margin-left: 20px;
        flex: 1 1;
        display: flex ; 
        flex-direction: column;
        justify-content: space-between;

        & > div {


        }

        & #chart {
            width: calc(100% - 40px);
            height: calc(40% - 40px - 10px);
            padding: 20px 20px;

            &--nodata {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;

                & svg {
                    height: 300px;
                }

                & p {

                    font-weight: 500;
                }
            }
        }

        & #history {
            width: 100%;
            height: calc(60% - 10px);
            display: flex;
            flex-direction: column;
        }

        & .history {
            &__title {
                height: 50px;
                display: flex;
                align-items: center;

                & > .tc-icons {
                    width: 20px;
                    height: 20px;
                    margin: 0px 10px;

                    & svg {
                        width: 100%;
                        height: 100%;
                    }
                }

                & h1 {
                    margin: 0;
                }

                &--buttons {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    height: 100%;
                    flex: 1 1;


                    & > div {
                        width: 15px;
                        padding: 5px;
                        height: 15px;
                        margin: 0px 10px;
                        cursor: pointer;
                    }
                }


            }
            &__container {
                height: calc(100% - 40px);
                & .tc-table__footer {
                    display: none;
                }

                & .tc-table__tbody {
                    height: calc(100% - 30px)!important;

                    & div, p {
                        font-size: 12px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                & .tc-table__thead {
                    & div {
                        font-size: 12px;
                    }
                }
            }
        }
    }


}


.withdrawal-payments {
    & .basepopup__container {
        width: 600px;
        height: 500px;
    }
    & .content {
        width: 100%;
        height: 100%;
        padding: 0px!important;

        & h1 {
            height: 30px;
            text-align: center;
        }

        & .tc-table {
            height: calc(100% - 50px);
        }
    }
}