

.user-manager {

    & .content {
        display: flex ;
        flex-direction: column;
        align-items: center ;
    }

    & label {
        @include labelStyle() ; 
    }

    & input {
        margin-bottom: 5px;
        @include inputStyle()
    }

    & .personal-infos {
        padding: 5px 10px 20px 10px;

        &-element {
            margin : 10px ; 
            display: flex;
            & > div {
                width: 50%;
                margin : 10px 20px; 
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            & input {
                text-align: center;
            }
        }
    }

    & .user-function {
        margin: 30px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;

        & #search-select {
            width: 200px;
        }
    }

    & .main-button {
        width : 120px;
    }
}