

.proposals-man {
    width: 100%;
    height: 100%;
    position: relative;

    & .swiper {
        height: 100%;
    }

    & .swiper-wrapper {
        display: flex;
        align-items: flex-start;
    }

    & .swiper-slide {
        width: 200px;
        height: calc(100% - 80px);
        margin: 20px 0px 60px 0px!important;
        text-align: center;
        font-size: 18px;
        background-color: transparent!important ;
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
      
    }

    & .swiper-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;

        &-bullet {
            width: 10px;
            height: 10px;
        }
    }


    & .button-return {
        width: 30px;
        height: 30px;
        top: 10px;
        left: 10px;
        position: absolute;
        margin-right: 20px;
        padding: 0!important;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none ; 
        @include buttonStyle("icons") ;
        z-index: 2000;

        & svg {
            width: 50%;
            height: 50%;
        }
    }

    &__listing {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &--header {
            width: calc(100% - 40px);
            height: 50px;
            border-bottom: 2px solid #1e4e6223;
        }

        &--container {
            width: calc(100% - 40px);
            height : calc(100% - 50px - 0px) ; 
            padding : 0px 10px ; 


            & .element {
                width: calc(100% - 30px);
                height: 100%;
                margin: 0px 20px 0px 10px;
                background-color: #fefefe;
                border: 1px solid hsla(0,0%,50%,.217);
                border-radius: 5px;
                box-shadow: 4px 4px 14px -4px #d5e0eab9;
                cursor: pointer;

                &--svg {
                    height: 300px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    & svg {
                        width: 200px;
                    }
                }

                &--name {
                    height: 50px;
                    font-weight: bold;
                    color: $color_1;
                }

                &--content {
                    width: 100%;
                    height : calc(100% - 300px - 50px) ;
                    
                    & p {
                        margin: 0;
                    }

                }
                


                .content-summary {

                    &--identification {
                        display: flex;
                        flex-direction: column;
                        overflow-y: scroll;
                    
                    }

                    &--container {
                        height: calc(100% - 50px);
                        //background-color: red;
                        
                    }
                    &--status {
                        width: 100%;
                        height: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        & > div {
                            width: calc(100% - 40px);
                            bottom: 0;
                            height: 30px;

                            font-weight: 600;
                            border-radius: 30px;
                            font-size: 15px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        &--configured div {
                            border: 1.5px solid green;
                            color: green;
                        }

                        &--unconfigured div {
                            border: 1.5px solid red;
                            color: red;
                        }
                    }
                }
                


            }
        }


    }
}

// * LEADER IDENTIFICATION
.leader-identification {
    width: 100%;
    height: 100%;
    position: relative;


    &__listing {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &--header {
            width: calc(100% - 40px - 30px);
            height: 50px;
            border-bottom: 2px solid #1e4e6223;
            //background-color: red;
            padding-left: 30px;
            display: flex;
            align-items: center;
        }

        &--container {
            width: calc(100% - 40px);
            height : calc(100% - 50px - 0px) ; 
            padding : 0px 10px ; 


            & .element {
                width: calc(100% - 30px);
                height: 100%;
                margin: 0px 20px 0px 10px;
                height: 100%;
                background-color: #fefefe;
                border: 1px solid hsla(0,0%,50%,.217);
                border-radius: 5px;
                box-shadow: 4px 4px 14px -4px #d5e0eab9;
                cursor: pointer;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;

                &--svg {
                    height: 300px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & svg {
                        width: 100%;
                        height: 100%;
                    }

                }

                &--infos {
                    height: 100px;


                    & h1 {

                    }

                    & p {
                        font-size: 14px;
                        font-weight: 300;
                        font-style: italic;
                        color: $color_1;

                    }

                }

                &--actions {
                    width: 100%;
                    height: 80px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & > div {
                        height: 30px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-left: 1px solid rgba(128, 128, 128, 0.368);
                        padding: 0px 15px;
                        position: relative;

                        &:last-child {
                            border-right: 1px solid rgba(128, 128, 128, 0.368);
                        }

                        & .tc-icons {
                            min-width: 20px;
                            height: 20px;


                            & svg {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        & .id-badge {

                        }

                        & p {
                            display: none; 
                            margin: 0!important;
                            position: absolute;

                            top: calc(100% + 10px);
                        }

                        &:hover p {
                            display: flex;
                        
                        }

                    }
                }

                &--main_representative {
                    position: absolute;
                    width: calc(100% - 40px);
                    margin-bottom: 20px;
                    bottom: 0;
                    height: 30px;
                    border: 1.5px solid $color_1;
                    font-weight: bold;
                    border-radius: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }


            }
        }

        &--nodata {
            width : 100% ; 
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & svg {
                height: 300px;
            }
            
        }


    }
}

.leader-identification-manager {

    & .content {
        width: auto;
        display: flex; 
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;

        & .name, .address, .info-leader {
            & input, .dropdown {
                width : 100%
            }
            
            & input {
                @include inputStyle() ; 
            }
        }

        & .other_infos {
            width: 100%; 
            height: 300px;
            display: flex;
            flex-direction: row;

            & > div {
                display: flex;
                flex-direction: column;
                align-items: center;
            }



        }

        & input:focus {
            outline: none;
        }

        //BLOC 
        & > div{
            display: flex;
            flex-direction: column;
            align-items: center;
            //width: calc(100% - 40px);
            margin-top: 10px;
            //border-left: 2px solid $color_1;

            &:nth-child(2n + 1) {
                //background-color: blue;
                padding : 10px 30px;
            }


            label {
                margin: 10px 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: bold;
                color : $color_1 ; 
            }




        }
    }

    & .contact {
        width : calc(100% - 40px);
        height: 300px;
        padding: 0px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        & .name input {
            text-align: center;
        }

        & .insert, .thead, .tbody {
            width: calc(100% - 40px);
            display: flex;
            border-radius: 5px;
            position: relative;

            & .add_button {
                width: 25px;
                height: 25px;
                border-radius: 50%!important;
                display: flex;
                justify-content: center;
                position: absolute;
                right : -35px; 
                top: 50%;
                transform: translateY(-50%);
                align-items: center;
                background-color: $color_4;
                border-radius: 5px 0px 0px 5px;
                cursor: pointer;

                & .tc-icons {
                    width: 90%;
                    height: 90%;
                    display: flex;
                    justify-content: center;
                    align-items: center;


                    & svg {
                        max-width: 50%;
                        max-height: 50%;
                    }

                }

                &:hover {
                    background-color: $color_3;
                }
            }

            & #label {
                width : calc(30% - 20px) ; 
                background-color: white;

            }

            & #type {
                width : 35%;

            }

            & #value {
                width : calc(35% - 20px) ; 
                background-color: white;

            }

        }

        & .insert {
            height: 40px;
            background-color: white;
            border: 1px solid rgba(1, 54, 1, 0.129) ;
            z-index: 2000;

            & #type {
                border-right: 1px solid rgba(1, 54, 1, 0.129);
                border-left: 1px solid rgba(1, 54, 1, 0.129);


            }

            & .dropdown {
                border : none ; 

                &--list {
                    top: 40px!important;
                }
            }

        }

        & .thead {
            z-index: 1000;
            width: calc(100% - 40px);
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            & > div {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-weight: 600;
                font-style: italic;
            }
        }

        & .thead #label, .thead #value {
            padding: 0px 10px;
            border: inherit!important;
        }

        & input {
            border: none!important;
        }

        & input {
            width : calc(100% - 20px) ; 
            padding: 0px 10px;
            font-size: 13px;
        }

        & .tbody {
            width: calc(100% - 40px);
            height: calc(100% - 90px);
            padding: 0px 30px;
            z-index: 1000;
            display: flex;
            flex-direction: column;
            overflow-x: visible;
            overflow-y: scroll;

            & > div {
                //padding-bottom : 10px;
                & > div {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    position : relative ;
                    font-size: 12px;
                    border: none!important;

                    & > div {

                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
    
                    & > div:nth-child(1), div:nth-child(3) {
                        display: flex;
                        padding: 0px 10px;
                    }
                }
            }

            & .nodata {
                width : 100% ; 
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                & > div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 300;
                    font-style: italic;
                }

                & svg {
                    width: 150px;
                }
            }



            & .tc-icons {
                width: 10px;
            }

            & .delete_button {
                position: absolute;
                width: 20px;
                height: 20px;
                border-radius: 50%!important;
                display: flex;
                justify-content: center;
                position: absolute;
                left : -25px; 
                top: 50%;
                transform: translateY(-50%);
                align-items: center;
                background-color: rgb(236, 1, 1);
                border-radius: 5px 0px 0px 5px;
                cursor: pointer;

                & .tc-icons {
                    width: 90%;
                    height: 90%;
                    display: flex;
                    justify-content: center;
                    align-items: center;


                    & svg {
                        max-width: 50%;
                        max-height: 50%;
                    }

                }

                &:hover {
                    background-color: rgb(200, 0, 0);
                }
            }
        }

    }

    & .address {
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        & input {
            margin-bottom: 10px;
        }

        & #zip_code {
            width: 100px!important;

            & input { text-align : center}
        }
        

    }
}

// * CONFIGURATION
.proposal-config {

    & > div {
        margin: 50px 0px ;
        display: flex;
        flex-direction: column;
        align-items: center;
        & label {
            @include labelStyle() ;
        }
    
        & input, .dropdown {
            width : 100%
        }
        
        & input {
            @include inputStyle() ; 
        }
    }

    & .tc-datepicker-popper {
        z-index: 2000 ;
    }


    
}

// * PROPOSAL CONTEXT
.proposal-context {
    width: calc(100%);
    height: calc(100%);
    display: flex;
    justify-content: center;
    align-items: center;

    & .quill {
        width: calc(100% - 200px);
        height: calc(100% - 100px);
        background-color: white;

        & .ql-container {
            height: calc(100% - 42px);
        }
    }


}