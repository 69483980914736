

.raw_materials-man {
    & .monetary-merch {

        & .quantity {
            font-weight: 600;

            &:hover {
                cursor : not-allowed;
                color: rgb(152, 152, 152);
            }
        }

        &--status-warning {
            width: 80%;
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            margin-top: 20px!important;
            color : darken($color_3, 20)!important
        }
    }
}