

.raw-materials {
    & .tc-table {
        &__header--custom {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-right: 5px;
            & .filter {
                display: flex;
                flex-direction: row;
                //width: 200px;
                align-items: center;
                justify-content: space-between;
                height: 35px;
                border-radius: 30px;
                background-color: rgb(236, 236, 236);
                border: 1px solid rgba(218, 213, 202, 0.282);
                position: relative;
    
                & .selector {
                    height: calc(100% - 4px);
                    border-radius: 30px;
                    position: absolute;
                    background-color: #FAFAFA;
                    z-index: 1000;
                    transition : left 120ms ; 
                }
    
                & .filter__item {
    
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    z-index: 2000;
                    cursor: pointer;
    
                    & p {
                        font-size: 12px;
                        &:first-child {
                            filter: grayscale(100%);
                        }
    
                        &:last-child{
                            height: 80%;
                            border-radius: 30px;
                            padding: 2.5px 5px;
                            margin-left: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
    
                    &--active {
                         & p {
                            font-weight: bold;
                         }
    
                    }
    
                    &--all {
                        & p:last-child {
                            color: rgb(0, 95, 147);
                            background-color: #e3e9fc;
                        }
                    }
    
                    &--used {
                        & p:last-child {
                            color: rgb(147, 86, 0);
                            background-color: #fcefe3;
                        }
                    }
    
                    &--available{
                        & p:last-child {
                            color: rgb(137, 147, 0);
                            background-color: #f8fce3;
                        }
                    }
    
                    &--to_validate {
                        & p:last-child {
                            color: #b67600;
                            background-color: #ffb22344;
                        }
                    }
    
                    &--sent {
                        & p:last-child {
                            color: #5a21ce!important;
                            background-color: #9470db23!important;
                        }
                    }
    
    
    
                }
            }
        }
    }
}