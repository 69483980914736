

.proposals {
    width: 100%;
    height: 100%;
    
    .stepsText {
        position: fixed;
    }


}