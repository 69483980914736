

.detail-org {
    width: calc(100%);
    height: calc(100%);
    position: relative;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    & .button-return {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 10px;
        left: 10px;
        padding: 0!important;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none ; 
        @include buttonStyle("icons") ;

        & svg {
            width: 50%;
            height: 50%;
        }
    }

    & .generalities {
        width: calc(25% - 30px - 20px);
        height: calc(100% - 30px - 50px);
        padding : 0px 10px ; 
        margin-top: 50px;
        background-color: #FEFEFE;
        border: 1px solid rgba(128, 128, 128, 0.217);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        -webkit-box-shadow: 4px 4px 14px -4px #d5e0eab9;  
        box-shadow: 4px 4px 14px -4px #d5e0eab9;  
        position: relative;

        & .button-edit {
            position: absolute;
            width: 30px;
            height: 30px;
            top: 10px;
            right: 10px;
            padding: 0!important;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none ; 
            @include buttonStyle("icons") ;
    
            & svg {
                width: 50%;
                height: 50%;
            }
        }

        & .title {
            height: 60px;
            margin-top: 50px;
            padding: 0px 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            & h1 {
                text-align: center;
            }
        }

        & .logo {
            width: 100%;
            margin-top: 5px;
            margin-bottom: 10px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            
            & img {
                max-height: 80px;
                border-radius: 5px;
                max-width: 70%;
                //border: 1px solid rgba(128, 128, 128, 0.147);
            }
        }



        & > p {
            margin: 10px 0px;

            & > span:first-child {
                font-weight: bold;
                text-decoration: underline;
            }
        }

        & span {
            margin : 2px 0px
        }

        & #postal_address {
            display: flex;
            flex-direction: column;

            & > p {
                display: flex;
                flex-direction: column;
                margin: 0;
            }
        }
    }

    & .modules {
        width: calc(75% - 30px);
        height: calc(100% - 30px);
        display: flex;
        flex-direction: column;

        
        & > .categorizer-manager > .categorizer-manager__container {
            width: calc(100%);
            height : calc(100% - 50px) ; 
            background-color: #FEFEFE;
            border: 1px solid rgba(128, 128, 128, 0.217);
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            -webkit-box-shadow: 4px 4px 14px -4px #d5e0eab9;  
            box-shadow: 4px 4px 14px -4px #d5e0eab9;  
            overflow: hidden;
        }

        & .mini-load svg {
            width: 40px;
            height: 40px;
        }
    }
}