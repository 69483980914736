
.inv-detail {

    & .basepopup__container {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        max-height: inherit;
    }

    & .content {
        width: calc(100% - 40px);
        height: calc(100% - 30px);
        padding: 0px 20px;
        background-color: white;
    }

    & .mini-load .tc-icons {
        min-width: 40px;
        min-height: 40px;
    }

    &__header {
        width: calc(100% - 20px);
        height: 50px;
        //background-color: rebeccapurple;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px;

        & h1 {
            margin: 0;
        }

        &--status {

            display: flex;

            & > div {
                padding : 7.5px 15px ; 
                border-radius: 10px;
                margin: 0px 5px;
            }

            & .invoice {

                &--merchandises {

                    background: $color_gradient;
                    color: white;
                }
    
                &--payment {

                    background-color: rgb(193, 0, 0);
                    color: white;

                    &--paid {
                        background-color: green!important;
                    
                    }
                }

                &--exchange-rate {
                    display: flex;
                    border-radius: 10px;
                    position: relative;
                    border: 0.5px solid rgba(128,128,128,.258);

                    & .tc-icons {
                        margin-right: 5px;
                        position: relative;

                        & svg {
                            width: 15px;
                            height: 15px;
                        }
                    }

                    &--status {
                        position: absolute;
                        top: calc(100% + 3px);
                        font-style: italic;
                        font-size: 14px;
                        color: $color_1;
                        width: 100%;
                        font-weight: 300;
                        text-align: center;

                        &--paid {
                            color: green;
                        }

                        &--unpaid {
                            color: red;
                        }
                    }

                    & .lock {



                    }
                    
                    & .clock {
                        
                        & svg {
                            width: 15px;
                            height: 15px;
    
                            & path:first-child {
                                fill: white!important;
                            }

                        }

                    }

                    & p {
                        margin: 0;
                        color: #393939;
                    }
                }
            }

        }
        

    }

    &__container {
        width: 100%;
        height: calc(100% - 50px);
        display: flex;
        position : relative ; 

        & #categorizer {
            width:calc(65%);
            & .categorizer-manager {
                width: 100%;
                height: 100%;
                position: inherit;

                &__header {
                    width: 100%;
                    position: absolute;
                    border-bottom: .5px solid rgba(128,128,128,.258);
                    justify-content: flex-start;
                    padding: 0px;
                    width: calc(100%);
                    z-index : 1000 ; 

                    & .active {
                        height: calc(100% - 2px);
                        border-bottom: 2px solid $color_3;
                        background: rgb(243,167,59);
                        background: linear-gradient(0deg, rgba(250, 195, 118, 0.179) 1%, rgba(255,150,0,0) 75%);                        
                        
                        & p {
                            color : #393939!important ; 
                            font-weight: 550;
                        }

                        
                    }

                    & .tc-icons {
                        display: none;
                    }

                    & > div {
                        height: 100%;
                        padding: 0px 10px;
                        margin: 0;

                        &:first-child p {
                            padding-left: 0px 15px;
                        }
                        
                    }
                }

                &__container {
                    height: calc(100% - 50px);
                    padding-top: 50px;
                    display: flex;


                }
            }


        }

        & #viewer {
            width: 35%;
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            border-left: .5px solid rgba(128,128,128,.258);
            overflow-y: scroll;

            &--update-file {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;

                & button {
                    @include buttonStyle();
                    border: none;
                    letter-spacing: 0.25px;
                    &:hover {
                        transform: scale(1.02);
                    }
                }
            }

            & iframe {
                border: none;
                width: 100%;
                height: calc(100% - 50px);
            }

            & img {
                width: auto;
                height: calc(100% - 50px);
            }
        }
    }

    &__generalities {
        width: calc(100% - 20px);
        height: calc(100% - 30px - 30px);
        padding-top: 30px;
        margin-bottom: 30px;
        margin-right: 20px;
        position: relative;

        & .edit-control {
            position: absolute;
            display: flex;
            right: 0px;
            top: 10px;

            & > div {
                padding: 5px 10px;
                font-size: 13px;
                color: white;
                cursor: pointer;
                border-radius: 10px;
            }

            &--true {
                background-color: green;
                margin-right: 5px;
            }

            &--false {
                background-color: rgb(196, 2, 2);
            }

            &--button {
                background-color: darken($color_3, 10);
            }


        }

        & .informations--header, .payments--header, .merchandises--header {
            height: calc(25px - 5px);
            display: flex;
            align-items: center;
            padding-bottom: 5px;
            border-bottom: 1px solid rgba(128,128,128,.258);

            & h1{
                color : #393939; 
                text-transform: capitalize;
            }
        }

        & .informations--container, .payments--container, .merchandises--container {
            height: calc(100% - 40px - 10px);
            padding-top : 10px ; 

            & .photo {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                & img {

                    height: calc(100% - 10px);
                   
                }

                & .no-pic {
                    width: 70px;
                    height: auto;
                    display: flex;
                    justify-content: center ; 
                    align-items: center;
        
                    & svg {
                        width: 90%;
                    }
                }
            }
        }


        & #informations {
            height: 140px;

            & #inv-number, #date, #amount {
                & input {
                    @include inputStyle();
                    margin: 2.5px 0px 7.5px 0px;
                    width: 200px;
                }
            }

            & #id_supplier #search-select {
                width: 220px;
                margin-top: 2.5px;

                & .mini-load {
                    & svg {
                        width: 20px;
                    }
                }

            }


            & .informations {
                
                &--header {
                    display: none ; 

                    & h1{
                        color : #393939
                    }
                }

                &--container {
                    margin-top: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    & > div {
                        display: flex;
                        flex-direction: column;
                        margin: 0px 0px;
                        width: 50%;

                        & p {
                            margin: 0;
                            &:first-child {
                                font-weight: 600;
                            }

                            &:last-child {
                                opacity: 0.7;
                            }
                        }
                    }
                }
            }
        }

        & #payments, #merchandises {
            width: 100%;
            height: calc(50% - 70px);
 
            & .payments--no-data, .merchandises--no-data {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                & svg {
                    height: 150px;
                }
            }

        }
    }

    &__generalities .tc-table__header {
        display: none;
    }

    &__generalities, &__payments {
        & .tc-table {
            //max-width: 80%;
            height: 100%;
            border-radius: 10px;
            overflow: hidden;
            border-bottom: 1px solid rgba(128,128,128,.258); 

            & * {
                font-size: 11px;
            }
        }

        & .tc-table__footer {
            display: none;
        }

        & .tc-table__thead {
            //background-color: rgba(128,128,128,.258)!important;
            border-bottom: 0.5px solid rgba(128,128,128,.258);
            height: 30px!important;

            & * {
                //color: #6d727a!important;
                //fill: #6d727a!important;
                font-weight: 500;
            }
        }

        & .tc-table__tbody {
            height: calc(100% - 30px)!important;

            &--row--column {
                border: 0.5px solid rgba(128,128,128,.258);

                & * {
                    color : #393939!important;
                    font-weight: 300;
                }
            }
        }
    }


    &__payments {
        width: calc(100% - 0px);
        height: calc(100% - 20px);
        margin: 10px 0px;
        display: flex;

        & #transaction {
            width: calc(400px - 20px);
            margin: 0px 10px;
            background-color: white;
            border-radius: 10px;
            //-webkit-box-shadow: 5px 6px 15px -1px rgba(0,0,0,0.214); 
            //box-shadow: 5px 6px 15px -1px rgba(0, 0, 0, 0.214);
            border: 0.5px solid rgba(128, 128, 128, 0.258);
            display: flex;
            flex-direction: column;
            align-items: center;


            & #external {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                margin-bottom: 10px;
                & p:first-child {
                    font-weight: 300;
                    font-size: 12px;
                }
            }

            &--paid {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                & svg {
                    width: 200px;
                }

                & p {
                    font-size: 17px;
                    font-weight: 600;
                }
            }

            & > div {
                display: flex;
                flex-direction: column;
                align-items: center;
            }


            & label {
                margin: 0;
                text-align: center;
                font-weight: 300;
                font-size: 13px;
                margin-bottom: 4px;
            }

            & .id_trans {
                width: 90%;

                & #search-select {
                    width: 100%;
                }
            }

            & .code {
                margin: 20px 0px;
                & p {
                    margin: 0;
                    text-align: center;

                    &:first-child {
                        font-weight: 300;
                        font-size: 13px;
                    }

                    &:last-child {
                        font-weight: 600;
                    }
                }
            }

            & .date {
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;

                & input {
                    @include inputStyle(); 
                    text-align: center;
                }

                & .tc-datepicker-popper {
                    z-index: 1001;
                }

            }

            & .amount {
                width: 100%;
                margin-top: 10px;

                &__container {
                    width: 80%;
                    border: 0.5px solid rgba(128, 128, 128, 0.258);
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    -webkit-box-shadow: 0px 7px 7px -4px rgba(0,0,0,0.11); 
                    box-shadow: 0px 7px 7px -4px rgba(0,0,0,0.11);

                    & > div {
                        display: flex;
                        flex-direction: column;
                        margin: 10px 0px;

                        & p {
                            text-align: center;
                            margin: 0;
                            &:first-child {
                                color : #393939!important;
                                font-weight: 300;
                            }
                            &:last-child {
                                margin-top : 2px ; 
                                font-size: 18px;
                            }
                        }

                    }

                    &--input {
                        width: calc(90% - 20px);
                        padding: 10px 10px;
                        border-radius: 10px;
                        border: 0.5px solid rgba(128, 128, 128, 0.258);
                        -webkit-box-shadow: 0px 7px 7px -4px rgba(0,0,0,0.11); 
                        box-shadow: 0px 7px 7px -4px rgba(0,0,0,0.11);
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        & .status {
                            width: 100%;
                            margin: 5px 0px 15px 0px;
                            text-align: center;
                            color: white;

                            &--error {
                                background: rgb(218,17,0);
                                background: linear-gradient(90deg, rgba(218,17,0,0) 5%, rgba(218,17,0,1) 25%, rgba(218,17,0,1) 75%, rgba(218,17,0,0) 95%);    
                            }

                            &--success {
                                background: rgb(65, 147, 6);
                                background: linear-gradient(90deg, rgb(65, 147, 6, 0) 5%, rgb(65, 147, 6) 25%, rgb(65, 147, 6) 75%, rgb(65, 147, 6, 0) 95%);
    
                            }
                        }

                        & input {
                            background-color: #f3f4f6;
                            border: 1px solid #f3f4f6;
                            border-radius: 5px;
                            width: calc(100% - 10px);
                            padding: 10px 0px 10px 10px;
                            text-align: start;
                        }
                    }

                    &--remaining {

                        & .status {
                            &--paid {
                                padding : 5px 7.5px ; 
                                background-color: green;
                                border-radius: 10px;
                                color: white;
                            }

                            &--error {
                                padding : 5px 7.5px ; 
                                background-color: rgb(218,17,0);
                                border-radius: 10px;
                                color: white;
                            }
                        }
                    }
                }



            }
        }

        & #history {
            width: calc(100% - 300px - 20px);
            height : 100% ; 
            margin: 0px 10px;
            border-radius: 10px;
            //-webkit-box-shadow: 5px 6px 15px -1px rgba(0,0,0,0.214); 
            //box-shadow: 5px 6px 15px -1px rgba(0, 0, 0, 0.214);
            border: 0.5px solid rgba(128, 128, 128, 0.258);
        }

        & .main-button {
            margin-top: 20px;
        }

        & .history {

            &--container {
                width: 100%;
                height: calc(100% - 50px);

                & .tc-table {
                    width: 100%;
                    border-bottom: none!important;
                    max-width: inherit!important;
                }
            }

            &--balance {
                //background-color: red;
                height: 50px;
                border-top: 0.5px solid rgba(128, 128, 128, 0.258);
            }

            &--empty {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                & svg {
                    width: 200px;
                }

                & p {
                    width: 90%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }
            }
        }

        & .external-payment {
            width: 100%;
            height: 100%;
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            & .currencies_values {
                width: calc(100% - 80px);
                margin-bottom: 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
    
    
                & > div {
                    width: 100%;
                    position: relative;
    
                    & input {
                        width: calc(100% - 35px - 5px);
                        background-color: #f3f4f6;
                        border: 1px solid #f3f4f6; 
                        border-radius: 5px;
                        padding: 10px 35px 10px 5px  ; 
        
                        &:focus {
                            outline : none ; 
                            border: 1px solid $color_3;
                        }
                    }
    
                    &::after {
                        width: 25px;
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: white;
                        border: 2px solid rgba(128,128,128,.258);
                        border-radius: 5px;
                        right: 5px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 12px;
                        padding: 5px 10px;
    
                    }
    
                    &:nth-child(1) {
                        margin-bottom: 10px;
                        &::after {
                            content : "EUR"
                        }
                    }
    
                    &:nth-child(3)::after {
                        content : "IDR"
                    }
    
                }

                & > .tc-icons {
                    background-color: white;
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    z-index: 100;
                    border-radius: 50%;

                    
                }
    
    
                .exchangeRateForEuro {
                    position: absolute;
                    bottom: -30px;
                    color : $color_1 ; 
                    font-size: 12px;
                    left: 50%;
                    font-style: italic;
                    transform: translateX(-50%);
                    display: flex;
                    justify-content: center;
                }
    
    
    
    
    
                & .tc-minibox {
    
                }
            }
        }
    }


    &__associated-merch {
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;

        & .tc-table {
            
            &__thead {
                height: 30px!important;   
            }

            &__tbody {
                height : calc(((100% - 50px) - 30px) - 30px)!important
            }
        }
        & .photo {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            & img {

                height: calc(100% - 10px);
               
            }

            & .no-pic {
                width: 70px;
                height: auto;
                display: flex;
                justify-content: center ; 
                align-items: center;
    
                & svg {
                    width: 90%;
                }
            }
        }
    }


}