
.merchandises {
    & .stepsText {
        position : fixed
    } 

    & .tc-table__header--custom {
        position: relative;
        align-items: center;
        & .refresh-button {
            position: absolute;
            width: 20px;
            height: 20px;
            cursor: pointer;
            left: 15px;
            transition: all 110ms;

            &::before {
                content: "Refresh";
                position: absolute;
                display: none;
                top: calc(100% + 5px);
                font-size: 13px;
                background-color: white;
                border-radius: 10px;
                border : 1px solid rgb(174, 173, 173) ; 
                padding: 5px 10px;
                -webkit-box-shadow: 1px 6px 6px -1px rgba(0,0,0,0.24); 
                box-shadow: 1px 6px 6px -1px rgba(0,0,0,0.24);
            }

            &:hover {
                transform: scale(1.1);

                &::before{
                    display: flex;
                }
            }

            

            & .tc-icons {
                width: 100%;
                height: 100%;

                & svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }



    &_header {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: flex-end;
        align-items: center;

        & .thead_mode {
            height: 30px;
            background-color: #007595;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin: 5px 10px;
            border-radius: 10px;
            position: relative;
            

            & svg {
                margin: 0px 10px;
                width: 15px;
                height: 15px;
            }

            & p {
                display: flex;
                flex: 1 1;
                margin-right: 10px;
                white-space: nowrap;
            }
    
            &--current {
                display: flex;


                & p {
                    color: white;
                    white-space: nowrap;
                }


            }

            &:hover {
                & .thead_mode--buttons {
                    display: flex;
                }
            }
    
            &--buttons {
                position: absolute;
                display: none;
                flex-direction: column;
                top: 100%;
                left: 0;
                padding-top: 2.5px;
                z-index: 1000; 

                & > div {
                    padding: 10px 0px;
                    background-color: rgb(246, 253, 255);
                    border: 1px solid rgb(192, 237, 252) ;
                    display: flex;
                    cursor: pointer;
                    justify-content: center;
                    align-items: center;

                    &:first-child {
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                    }
                    &:last-child {
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                    }
                    & p {
                        padding: 0;
                        margin: 0;
                        margin-right: 10px;
                    }
                }
            }
        }

        & .filter {
            display: flex;
            flex-direction: row;
            //width: 200px;
            align-items: center;
            justify-content: space-between;
            height: 35px;
            border-radius: 30px;
            background-color: rgb(236, 236, 236);
            border: 1px solid rgba(218, 213, 202, 0.282);
            position: relative;

            & .selector {
                height: calc(100% - 4px);
                border-radius: 30px;
                position: absolute;
                background-color: #FAFAFA;
                z-index: 1000;
                transition : left 120ms ; 
            }

            & .filter__item {

                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                z-index: 2000;
                cursor: pointer;

                & p {
                    font-size: 12px;
                    &:first-child {
                        filter: grayscale(100%);
                    }

                    &:last-child{
                        height: 80%;
                        border-radius: 30px;
                        padding: 2.5px 5px;
                        margin-left: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                &--active {
                     & p {
                        font-weight: bold;
                     }

                }

                &--all {
                    & p:last-child {
                        color: rgb(0, 95, 147);
                        background-color: #e3e9fc;
                    }
                }

                &--manufactured_product {
                    & p:last-child {
                        color: rgb(147, 86, 0);
                        background-color: #fcefe3;
                    }
                }

                &--finished_product{
                    & p:last-child {
                        color: rgb(137, 147, 0);
                        background-color: #f8fce3;
                    }
                }

                &--to_validate {
                    & p:last-child {
                        color: #b67600;
                        background-color: #ffb22344;
                    }
                }

                &--sent {
                    & p:last-child {
                        color: #5a21ce!important;
                        background-color: #9470db23!important;
                    }
                }



            }
        }
    }

    & .table__code {
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;

        &:hover .table__code--view {
            display: flex;
        }

        & .tc-icons {
            width: 20px;
            height: 20px;
            padding: 5px;
            & svg {
                width: 90%;
                height: 90%;
            }
        }



        & p {
            margin: 0;
            display: flex;
            align-items: center;
        }

        &--view {
            margin-top: 20px ;
            position: absolute;
            top: 100%;
            width: 400px;
            height: 250px;
            left: 10px;
            display:none ; 
            flex-direction: column;
            z-index: 1000;


            &--modules {
                display: flex;

                & > div {
                    width: 100%;
                    height: 100%;
                    border: 2px solid rgb(237, 237, 237);
                    border-radius: 10px;
                    background-color: #FEFEFE;
                    padding: 0px 10px;
                    padding-top: 30px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    -webkit-box-shadow: 9px 9px 10px -2px rgba(0,0,0,0.08); 
                    box-shadow: 9px 9px 10px -2px rgba(0,0,0,0.08);

                    & > div {
                        display: flex;
                        flex-direction: column;
                        padding: 10px 0px;
                        
                    }

                    & > h1 {
                        position: absolute;
                        top: 0;
                        height: 20px;
                        width: 100%;
                        text-align: center;
                    }

                    & h2 {
                        color : #383838 ; 
                        font-size: 13px;
                    }

                    & .qr-view {
                        & img {
                            height: 50px;
                            margin: 5px;
                        }
                    }
                    & .pictures {
                        & img {
                            height: 100px;
                            margin: 5px;
                        }
                    }

 
                }
            }



        }
    }

    & .photo {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        & img {

            height: calc(100% - 10px);
           
        }

        & .no-pic {
            width: 70px;
            height: auto;
            display: flex;
            justify-content: center ; 
            align-items: center;

            & svg {
                width: 90%;
            }
        }
    }

    & .tc-table {
       &__thead {
            height: 40px!important;
        }

        &__tbody {
            height: calc(((100% - 50px) - 40px) - 30px)!important;
        }
    }


}