
.invoices {
    
    & .stepsText {
        position: fixed;
    }

    & .tc-table {
        &__header--custom {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-right: 5px;
            & .filter {
                display: flex;
                flex-direction: row;
                //width: 200px;
                align-items: center;
                justify-content: space-between;
                height: 35px;
                border-radius: 30px;
                background-color: rgb(236, 236, 236);
                border: 1px solid rgba(218, 213, 202, 0.282);
                position: relative;
    
                & .selector {
                    height: calc(100% - 4px);
                    border-radius: 30px;
                    position: absolute;
                    background-color: #FAFAFA;
                    z-index: 1000;
                    transition : left 120ms ; 
                }
    
                & .filter__item {
    
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    z-index: 2000;
                    cursor: pointer;
    
                    & p {
                        font-size: 12px;
                        &:first-child {
                            filter: grayscale(100%);
                        }
    
                        &:last-child{
                            height: 80%;
                            border-radius: 30px;
                            padding: 2.5px 5px;
                            margin-left: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
    
                    &--active {
                         & p {
                            font-weight: bold;
                         }
    
                    }
    
                    &--all {
                        & p:last-child {
                            color: rgb(0, 95, 147);
                            background-color: #e3e9fc;
                        }
                    }
    
                    &--raw_materials {
                        & p:last-child {
                            color: rgb(147, 86, 0);
                            background-color: #fcefe3;
                        }
                    }
    
                    &--finished_products{
                        & p:last-child {
                            color: rgb(137, 147, 0);
                            background-color: #f8fce3;
                        }
                    }
    
                    &--to_validate {
                        & p:last-child {
                            color: #b67600;
                            background-color: #ffb22344;
                        }
                    }
    
                    &--sent {
                        & p:last-child {
                            color: #5a21ce!important;
                            background-color: #9470db23!important;
                        }
                    }
    
    
    
                }
            }
        }
    }

    & .tc-table__header--custom {
        position: relative;
        align-items: center;
        & .refresh-button {
            position: absolute;
            width: 20px;
            height: 20px;
            cursor: pointer;
            left: 15px;
            transition: all 110ms;

            &::before {
                content: "Refresh";
                position: absolute;
                display: none;
                top: calc(100% + 5px);
                font-size: 13px;
                background-color: white;
                border-radius: 10px;
                border : 1px solid rgb(174, 173, 173) ; 
                padding: 5px 10px;
                -webkit-box-shadow: 1px 6px 6px -1px rgba(0,0,0,0.24); 
                box-shadow: 1px 6px 6px -1px rgba(0,0,0,0.24);
            }

            &:hover {
                transform: scale(1.1);

                &::before{
                    display: flex;
                }
            }

            

            & .tc-icons {
                width: 100%;
                height: 100%;

                & svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.invoices-man {

    & label {
        @include labelStyle() 
    }

    & input {
        @include inputStyle() ; 
    }

    & .inv-number, .payer, .date {
        & input {
            text-align: center;
        }
    }

    & .basepopup__container {
        width: 90%;
        max-height: inherit;
        height: calc(90% - 30px);
    }

    & .content {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: calc(100% - 30px);
        padding: 0;
    }


    &--leftside {
       border-right : 2px solid rgba(206, 206, 206, 0.688) ; 
       display: flex;
       flex-direction: column;
       align-items: center;
        
        & > div{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 5px 40px;
        }

        & .type {
            width: 100%;
            padding: 0;
            & .dropdown {
                width: 70%;
            }
        }
    }

    &--rightside {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        & > label {
            @include labelStyle() ; 
            height: 40px;
            //align-items: flex-start;
        }



        & .import {
            width: 300px;
            height: 400px;
            margin: 10px 40px 30px 40px;
        }

        & .preview {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &--header {
                width: calc(100% - 20px);
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;

                & button {
                    border: none ; 
                    @include buttonStyle() ;
                }
            }
            &--body {
                width: 100%;
                height: calc(100% - 50px);
                display: flex;
                justify-content: center;
                align-items: center;

                & img {
                    max-width: 100%;
                    height: calc(100% - 40px);
                }

                & iframe {
                    width: 100%;
                    height: 100%;
                    border: none!important;
                }
            }
        }
    }

    & .main-button {
        width: 100px;
        margin-top: 20px;

    }
    
}

.inv-update-file {
    z-index: 2600;

    & .basepopup__container {
        width: 400px ; 
    }

    & label {
        @include labelStyle() ; 
    }

    & .content {
        padding-left: 20px ; 
        padding-right: 20px ; 
        width: calc(100% - 40px) ; 
        padding-bottom : 30px  ; 
        & > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        & .download-file {
            width: 200px;
            height: 200px;
            padding-top: 20px;
            border: 1px solid rgba(128, 128, 128, 0.541);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 15px 0px;

            &:hover {
                border-color: $color_3;
            }

            & a {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
    
            & .tc-icons {
                height: 50px;
                & svg {
                    width: 100%;
                    height: 100%;
                }
            }

            & p {
                width: calc(100% - 30px);
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }




}