//$font : 'Roboto Slab', serif;
$font : 'Lato', sans-serif;
$background_app : #fefefe;


$color_1 : #C3281B ; 
$color_2 : #D03F37 ; 
$color_3 : #F3A73B ; 
$color_4 : #FAD649 ; 

$color_gradient : linear-gradient(150deg, #f09517 0%,  #e1bb20 100%) ; 

@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}